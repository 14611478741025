import { useParams } from 'react-router-dom';
import UtilityMenu from './UtilityMenu/UtilityMenu';
import classes from './GlobalNav.module.css';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const GlobalNav = (props) => {
  const params = useParams();

  return (
    <nav className={classes.GlobalNav}>
      <header className={classes['GlobalNav__Header']}>
        <div className={classes['GlobalNav__HeaderInner']}></div>
      </header>
      <div className={classes['GlobalNav__Body']}>
        <div className={classes['GlobalNav__BodyInner']}>
          <NavLink
            to={`/schedule/stadium/${params.stadiumId}/zone/${params.zoneId}`}
            className={classes['GlobalNav__Link']}
            activeClassName={classes['GlobalNav__Link--active']}
          >
            구장 일정
          </NavLink>
          <NavLink
            to={`/social-requests/stadium/${params.stadiumId}/zone/${params.zoneId}`}
            className={classes['GlobalNav__Link']}
            activeClassName={classes['GlobalNav__Link--active']}
          >
            소셜매치 요청
          </NavLink>
        </div>
      </div>
      <div className={classes['GlobalNav__Footer']}>
        <a
          href='https://plabstadiumguide.oopy.io/'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
          style={{ fontWeight: 700 }}
        >
          공지사항
        </a>
        <a
          href='https://airtable.com/appJMFXvomJqnnxlX/shr7XN9QyNCSxWDDF'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          공실 알리기
        </a>
        <a
          href='https://airtable.com/appJMFXvomJqnnxlX/shrkxf9EFoszdA2hX'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          개별 일정 변경 요청
        </a>
        <a
          href='https://airtable.com/appJMFXvomJqnnxlX/shrfZ1FrtE9DnZOyx'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          정기 일정 변경 요청
        </a>
        <a
          href='https://plabfootball.notion.site/97fe4bc9390d48058f0e1a340335e4f9?pvs=74'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          런드리 임시 중단 서비스
        </a>
        <a
          href='https://plabstadiumguide.oopy.io/0c79d8c7-079a-400e-a577-814134aeef26/'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          구장관리 사이트 이용 가이드
        </a>
        <div className={classes['GlobalNav__FooterInner']}>
          <UtilityMenu />
        </div>
      </div>
    </nav>
  );
};

export default GlobalNav;
