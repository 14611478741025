import React, { useState } from 'react';
import ConfirmPopUp from '../PopUps/ConfirmPopUp/ConfirmPopUp';
import Button from '../UI/Input/Button/Button';
import ApprovalAndRejectionForm from './ApprovalAndRejectionForm/ApprovalAndRejectionForm';
import classes from './SocialRequestList.module.css';

const socialMatchStatus = {
  RELEASE: '공개',
  READY: '대기',
};

const socialRequestType = {
  ADD: '추가',
  CHANGE: '변경',
  CANCEL: '취소',
};

const socialRequestReason = {
  BAD_WEATHER: '기상악화',
  EVENT: '대회/행사',
  FACILITY_ISSUE: '시설문제',
  OWNER_DIRECT: '구장직접',
  LONG_TERM_RENTAL: '장기대관',
  ACADEMY: '아카데미',
  OTHER: '기타',
};

const SocialRequestList = (props) => {
  const {
    setSocialMatchList,
    socialRequestList,
    setSocialRequestList,
    zoneList,
  } = props;
  const [isApprovalAndRejectionFormOpen, setIsApprovalAndRejectionFormOpen] =
    useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [requestComment, setRequestComment] = useState('');
  const [isConfirmPopUpOpen, setIsConfirmPopUpOpen] = useState(false);

  const confirmPopUpHandler = () => {
    setIsConfirmPopUpOpen((prevState) => !prevState);
  };

  const ApprovalAndRejectionFormOpenHandler = () => {
    setIsApprovalAndRejectionFormOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className={classes['SocialRequestList']}>
        <h2>소셜매치 요청</h2>
        <div className={classes['SocialRequestList__ItemBox']}>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            요청
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            사유
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            상태
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            일시
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            구역
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            매니저 여부
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            신청 현황
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Actions']} `}
          >
            &nbsp;
          </div>
        </div>
        {!socialRequestList.length && <>요청 내역이 없습니다.</>}
        {socialRequestList.map((request) => {
          const zone = zoneList.find((zone) => zone.id === request.zoneId);
          const date = request.startDateTime.split('T')[0];
          const time = request.startDateTime.split('T')[1];

          return (
            <div
              key={request.id}
              className={classes['SocialRequestList__ItemBox']}
              onClick={() => {
                setRequestComment(request.comment);
                confirmPopUpHandler();
              }}
            >
              <div className={classes['SocialRequestList__Item']}>
                {socialRequestType[request.request_type]}
              </div>
              <div className={classes['SocialRequestList__Item']}>
                {socialRequestReason[request.reason]}
              </div>
              <div className={classes['SocialRequestList__Item']}>
                {socialMatchStatus[request.social_match_status]}
              </div>
              <div
                className={classes['SocialRequestList__Item']}
              >{`${date} ${time}`}</div>
              <div className={classes['SocialRequestList__Item']}>
                {zone?.name}
              </div>
              <div className={classes['SocialRequestList__Item']}>
                {request.is_manager ? 'O' : 'X'}
              </div>
              <div
                className={classes['SocialRequestList__Item']}
              >{`${request.confirmed_apply}/${request.max_player_cnt}`}</div>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Actions']}`}
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
              >
                {(request.isApproved === true ||
                  request.isApproved === null) && (
                  <Button
                    attribute={{ type: 'button' }}
                    classList={[
                      'Button--fit-content',
                      request.isApproved === false ||
                      request.isApproved === true
                        ? 'Button--Disabled'
                        : '',
                    ]}
                    onClick={() => {
                      setSelectedRequestId(request.id);
                      setRequestType('승인');
                      if (request.isApproved === null)
                        ApprovalAndRejectionFormOpenHandler();
                    }}
                  >
                    {request.isApproved === true ? '승인됨' : '승인'}
                  </Button>
                )}
                {(request.isApproved === false ||
                  request.isApproved === null) && (
                  <Button
                    attribute={{ type: 'button' }}
                    classList={[
                      'Button--fit-content',
                      'Button--Delete',
                      request.isApproved === true ||
                      request.isApproved === false
                        ? 'Button--Disabled'
                        : '',
                    ]}
                    onClick={() => {
                      setSelectedRequestId(request.id);
                      setRequestType('반려');
                      if (request.isApproved === null)
                        ApprovalAndRejectionFormOpenHandler();
                    }}
                  >
                    {request.isApproved === false ? '반려됨' : '반려'}
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {isApprovalAndRejectionFormOpen && (
        <ApprovalAndRejectionForm
          title={requestType}
          onSetSocialRequestList={setSocialRequestList}
          selectedRequestId={selectedRequestId}
          onClose={ApprovalAndRejectionFormOpenHandler}
        />
      )}
      {isConfirmPopUpOpen && (
        <ConfirmPopUp
          actionType={null}
          paragraph={requestComment}
          onClose={confirmPopUpHandler}
          onSubmit={() => {}}
        />
      )}
    </>
  );
};

export default SocialRequestList;
