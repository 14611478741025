import { useState } from 'react';

import Modal from '../../UI/Modal/Modal';
import Loader from '../../UI/Loader/Loader';
import Select from '../../UI/Input/Select/Select';
import Button from '../../UI/Input/Button/Button';
import FormControlWrap from '../../Scheduler/Forms/FormControlWrap/FormControlWrap';
import Textarea from '../../UI/Input/Textarea/Textarea';
import Checkbox from '../../UI/Input/Checkbox';
import useHttp from '../../../hooks/use-http';
import classes from './ApprovalAndRejectionForm.module.css';

const rejectReason = {
  '기준 미부합': 'STANDARD_UNMET',
  '부정적 경험': 'NEGATIVE_EXPERIENCE',
  기타: 'OTHER',
};

const ApprovalAndRejectionForm = (props) => {
  const { title, onSetSocialRequestList, selectedRequestId, onClose } = props;
  const [enteredReason, setEnteredReason] = useState('기타');
  const [enteredIsManger, setEnteredIsManger] = useState(false);
  const [enteredMemo, setEnteredMemo] = useState('');
  const { isLoading, sendRequest: sendSocialRequest } = useHttp();

  const changeIsMangerHandler = (evt) => {
    setEnteredIsManger(evt.target.checked);
  };

  const changeMemoHandler = (evt) => {
    const value = evt.target.value;
    setEnteredMemo(value);
  };

  const changeReasonHandler = (evt) => {
    const value = evt.target.value;
    setEnteredReason(value);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();

    const createSocialRequest = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        onSetSocialRequestList((prevState) => {
          return prevState.map((request) => {
            if (request.id === data.social_request_id) {
              if (title === '반려') {
                request.isApproved = false;
              } else if (title === '승인') {
                request.isApproved = true;
              }
              return request;
            }
            return request;
          });
        });
        return;
      }

      alert(data.message);
    };

    if (title === '반려') {
      sendSocialRequest(
        {
          urlPath: 'rejected-social-request/',
          method: 'POST',
          body: {
            social_request_id: selectedRequestId,
            reason: rejectReason[enteredReason],
            comment: enteredMemo,
          },
        },
        createSocialRequest
      );
    } else if (title === '승인') {
      sendSocialRequest(
        {
          urlPath: `social-requests/individual/${selectedRequestId}/approval/`,
          method: 'PUT',
          body: {
            reason: rejectReason[enteredReason],
            comment: enteredMemo,
          },
        },
        createSocialRequest
      );
    }
  };

  return (
    <Modal title={title} onClose={onClose} classList={['modalSmall']}>
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        {title === '반려' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label htmlFor='product-type' className={classes.InputLabel}>
                사유
              </label>
              <Select
                attribute={{
                  id: 'product-type',
                  value: enteredReason,
                  onChange: changeReasonHandler,
                }}
                options={['기준 미부합', '부정적 경험', '기타']}
              />
            </FormControlWrap>
          </div>
        )}
        {title === '승인' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label htmlFor='penalty-option' className={classes.InputLabel}>
                패널티
              </label>
              <Checkbox
                attribute={{
                  name: 'penalty-option',
                  onChange: changeIsMangerHandler,
                  defaultChecked: enteredIsManger,
                }}
              />
            </FormControlWrap>
          </div>
        )}
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap classList={['align--flex-start']}>
            <label
              htmlFor='memo'
              className={`${classes.InputLabel} ${classes.textarea}`}
            >
              코멘트
            </label>
            <Textarea
              attribute={{
                id: 'memo',
                value: enteredMemo,
                onChange: changeMemoHandler,
                placeholder: '메모',
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <Button attribute={{ type: 'submit' }}>확인</Button>
        </div>
      </form>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default ApprovalAndRejectionForm;
