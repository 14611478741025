import React, { useState } from 'react';
import ConfirmPopUp from '../PopUps/ConfirmPopUp/ConfirmPopUp';
import ApprovalAndRejectionForm from '../SocialRequestList/ApprovalAndRejectionForm/ApprovalAndRejectionForm';
import Button from '../UI/Input/Button/Button';
import SocialAddRequestForm from './SocialAddRequestForm/SocialAddRequestForm';
import classes from './SocialAddRequestList.module.css';

const socialRequestType = {
  ADD: '추가',
  CHANGE: '변경',
  CANCEL: '취소',
};

const SocialAddRequestList = (props) => {
  const { socialAddRequestList, setSocialAddRequestList, zoneList } = props;
  const [requestComment, setRequestComment] = useState('');
  const [requestType, setRequestType] = useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [isConfirmPopUpOpen, setIsConfirmPopUpOpen] = useState(false);
  const [isApprovalAndRejectionFormOpen, setIsApprovalAndRejectionFormOpen] =
    useState(false);
  const [isSocialAddRequestFormOpen, setIsSocialAddRequestForm] =
    useState(false);

  const confirmPopUpHandler = () => {
    setIsConfirmPopUpOpen((prevState) => !prevState);
  };

  const approvalAndRejectionFormOpenHandler = () => {
    setIsApprovalAndRejectionFormOpen((prevState) => !prevState);
  };

  const socialAddRequestFormOpenHandler = () => {
    setIsSocialAddRequestForm((prevState) => !prevState);
  };

  return (
    <>
      <div className={classes['SocialAddRequestList']}>
        <h2>소셜매치 추가 요청</h2>
        <div className={classes['SocialAddRequestList__ItemBox']}>
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
          >
            요청
          </div>
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
          >
            일시
          </div>
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
          >
            구역
          </div>
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
          >
            매니저 가능
          </div>
        </div>
        {!socialAddRequestList.length && <>요청 내역이 없습니다.</>}
        {socialAddRequestList.map((request) => {
          const zone = zoneList.find((zone) => zone.id === request.zoneId);
          const date = request.startDateTime.split('T')[0];
          const time = request.startDateTime.split('T')[1];
          return (
            <div
              key={request.id}
              className={classes['SocialAddRequestList__ItemBox']}
              onClick={() => {
                setRequestComment(request.comment);
                confirmPopUpHandler();
              }}
            >
              <div className={classes['SocialAddRequestList__Item']}>
                {socialRequestType[request.requestType]}
              </div>
              <div
                className={classes['SocialAddRequestList__Item']}
              >{`${date} ${time}`}</div>
              <div className={classes['SocialAddRequestList__Item']}>
                {zone?.name}
              </div>
              <div className={classes['SocialAddRequestList__Item']}>
                {request.isManager ? 'O' : 'X'}
              </div>
              <div
                className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Actions']}`}
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
              >
                {(request.isApproved === true ||
                  request.isApproved === null) && (
                  <Button
                    attribute={{ type: 'button' }}
                    classList={[
                      'Button--fit-content',
                      request.isApproved === false ||
                      request.isApproved === true
                        ? 'Button--Disabled'
                        : '',
                    ]}
                    onClick={() => {
                      setSelectedRequestId(request.id);
                      setRequestType('승인');
                      if (request.isApproved === null)
                        approvalAndRejectionFormOpenHandler();
                    }}
                  >
                    {request.isApproved === true ? '승인됨' : '승인'}
                  </Button>
                )}
                {(request.isApproved === false ||
                  request.isApproved === null) && (
                  <Button
                    attribute={{ type: 'button' }}
                    classList={[
                      'Button--fit-content',
                      'Button--Delete',
                      request.isApproved === true ||
                      request.isApproved === false
                        ? 'Button--Disabled'
                        : '',
                    ]}
                    onClick={() => {
                      setSelectedRequestId(request.id);
                      setRequestType('반려');
                      if (request.isApproved === null)
                        approvalAndRejectionFormOpenHandler();
                    }}
                  >
                    {request.isApproved === false ? '반려됨' : '반려'}
                  </Button>
                )}
              </div>
            </div>
          );
        })}
        <div
          className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Add-Btn']} `}
        >
          <Button
            attribute={{ type: 'button' }}
            classList={['Button--fit-content']}
            onClick={socialAddRequestFormOpenHandler}
          >
            추가 요청하기
          </Button>
        </div>
      </div>
      {isApprovalAndRejectionFormOpen && (
        <ApprovalAndRejectionForm
          title={requestType}
          onSetSocialRequestList={setSocialAddRequestList}
          selectedRequestId={selectedRequestId}
          onClose={approvalAndRejectionFormOpenHandler}
        />
      )}
      {isSocialAddRequestFormOpen && (
        <SocialAddRequestForm
          title='소셜매치 추가 요청'
          onSetSocialAddRequestList={setSocialAddRequestList}
          selectedRequestId={selectedRequestId}
          onClose={socialAddRequestFormOpenHandler}
        />
      )}
      {isConfirmPopUpOpen && (
        <ConfirmPopUp
          actionType={null}
          paragraph={requestComment}
          onClose={confirmPopUpHandler}
          onSubmit={() => {}}
        />
      )}
    </>
  );
};

export default SocialAddRequestList;
