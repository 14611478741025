import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import AuthContext from '../../contexts/auth-context';

import SocialRequestForm from './SocialRequestForm/SocialRequestForm';
import Button from '../UI/Input/Button/Button';
import classes from './SocialMatchList.module.css';

const socialMatchStatus = {
  CONFIRMED: '공개',
  NOT_CONFIRMED_CAN_NOT_RENT: '대기',
  NOT_CONFIRMED_CAN_RENT: '대기',
};

const SocialMatchList = (props) => {
  const { socialMatchList, setSocialMatchList, setSocialRequestList } = props;
  const params = useParams();
  const authCtx = useContext(AuthContext);

  const [isSocialRequestFormOpen, setIsSocialRequestFormOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const socialRequestFormOpenHandler = () => {
    setIsSocialRequestFormOpen((prevState) => !prevState);
  };
  const [requestType, setRequestType] = useState(null);

  const zoneList = authCtx.stadiumsList.find((stadium) => {
    return stadium.id === +params.stadiumId;
  }).zones;

  return (
    <>
      <div className={classes['SocialMatchList']}>
        <h2>소셜매치 일정</h2>
        <div className={classes['SocialMatchList__ItemBox']}>
          <div
            className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
          >
            상태
          </div>
          <div
            className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
          >
            일시
          </div>
          <div
            className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
          >
            구역
          </div>
          <div
            className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
          >
            매니저 여부
          </div>
          <div
            className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
          >
            신청 현황
          </div>
        </div>
        {socialMatchList.map((match) => {
          const zone = zoneList.find((zone) => zone.id === match.zoneId);
          const date = match.time.startTime.split('T')[0];
          const time = match.time.startTime.split('T')[1];
          return (
            <div key={match.id} className={classes['SocialMatchList__ItemBox']}>
              <div className={classes['SocialMatchList__Item']}>
                {socialMatchStatus[match.socialMatchStatus]}
              </div>
              <div
                className={classes['SocialMatchList__Item']}
              >{`${date} ${time}`}</div>
              <div className={classes['SocialMatchList__Item']}>
                {zone?.name}
              </div>
              <div className={classes['SocialMatchList__Item']}>
                {match.is_manager ? 'O' : 'X'}
              </div>
              <div
                className={classes['SocialMatchList__Item']}
              >{`${match.confirmed_apply}/${match.max_player_cnt}`}</div>
              <div
                className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Actions']}`}
              >
                <Button
                  attribute={{ type: 'button' }}
                  classList={['Button--fit-content']}
                  onClick={() => {
                    setSelectedProductId(match.id);
                    setRequestType('변경 요청');
                    socialRequestFormOpenHandler();
                  }}
                >
                  변경 요청
                </Button>
                <Button
                  attribute={{ type: 'button' }}
                  classList={['Button--fit-content']}
                  onClick={() => {
                    setSelectedProductId(match.id);
                    setRequestType('취소 요청');
                    socialRequestFormOpenHandler();
                  }}
                >
                  취소 요청
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      {isSocialRequestFormOpen && (
        <SocialRequestForm
          title={requestType}
          onSetSocialMatchList={setSocialMatchList}
          onSetSocialRequestList={setSocialRequestList}
          selectedProductId={selectedProductId}
          onClose={socialRequestFormOpenHandler}
        />
      )}
    </>
  );
};

export default SocialMatchList;
