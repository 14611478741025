import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Modal from '../../UI/Modal/Modal';
import Loader from '../../UI/Loader/Loader';
import Select from '../../UI/Input/Select/Select';
import InputDate from '../../UI/Input/InputDate';
import Button from '../../UI/Input/Button/Button';
import FormControlWrap from '../../Scheduler/Forms/FormControlWrap/FormControlWrap';
import Textarea from '../../UI/Input/Textarea/Textarea';
import Checkbox from '../../UI/Input/Checkbox';
import useHttp from '../../../hooks/use-http';
import AuthContext from '../../../contexts/auth-context';
import classes from './SocialAddRequestForm.module.css';

const timeOptions = (type, enteredDate, startDate) => {
  let options = [];
  const optionDate = enteredDate
    .toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replaceAll('. ', '-')
    .slice(0, -1);

  if (type === 'start_time') {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const optionTime = `${i < 10 ? `0${i}` : i}:${j === 0 ? '00' : j * 15}`;
        options.push({
          optionVal: `${optionDate}T${optionTime}`,
          optionTxt: optionTime,
        });
      }
    }
    return options;
  }

  if (type === 'end_time') {
    for (let i = 0; i < 25; i++) {
      for (let j = 0; j < 4; j++) {
        if (+startDate.optionTxt.split(':')[0] + i < 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i}`
              : +startDate.optionTxt.split(':')[0] + i
          }:${j === 0 ? '00' : j * 15}`;

          options.push({
            optionVal: `${startDate.optionVal.split('T')[0]}T${optionTime}`,
            optionTxt: optionTime,
          });
        }

        if (+startDate.optionTxt.split(':')[0] + i >= 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i - 24 < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i - 24}`
              : +startDate.optionTxt.split(':')[0] + i - 24
          }:${j === 0 ? '00' : j * 15}`;

          options.push({
            optionVal: `${new Date(
              startDate.optionVal.split('-')[0],
              +startDate.optionVal.split('-')[1] - 1,
              +startDate.optionVal.split('-')[2].split('T')[0] + 1
            )
              .toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
              .replaceAll('. ', '-')
              .slice(0, -1)}T${optionTime}`,
            optionTxt: optionTime,
          });
        }
      }
    }

    if (startDate.optionTxt.split(':')[1] === '00') {
      return (options = options.slice(4, -3));
    }

    if (startDate.optionTxt.split(':')[1] === '15') {
      return (options = options.slice(5, -2));
    }

    if (startDate.optionTxt.split(':')[1] === '30') {
      return (options = options.slice(6, -1));
    }
    if (startDate.optionTxt.split(':')[1] === '45') {
      return (options = options.slice(7));
    }
  }
};

const adjDateFormat = (dateObj) => {
  let ISODateFormat;

  ISODateFormat = `${dateObj.getFullYear()}-${
    dateObj.getMonth() < 9
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  }-${dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()}`;

  return ISODateFormat;
};

const SocialAddRequestForm = (props) => {
  const { title, onSetSocialAddRequestList, selectedProductId, onClose } =
    props;
  const today = new Date();
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const currentStadium = authCtx.stadiumsList.find(
    (stadium) => +stadium.id === +params.stadiumId
  );
  const [enteredStadiumZone, setEnteredStadiumZone] = useState(+params.zoneId);
  const [enteredIsManger, setEnteredIsManger] = useState(false);
  const [enteredDate, setEnteredDate] = useState(today);
  const defaultStartTime = today.toLocaleTimeString('ko-KR', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });
  const [enteredStartTime, setEnteredStartTime] = useState({
    optionVal: `${adjDateFormat(today)}T${defaultStartTime}`,
    optionTxt: defaultStartTime,
  });
  const [enteredMemo, setEnteredMemo] = useState('');
  const { isLoading, sendRequest: sendSocialRequest } = useHttp();

  const changeStadiumZoneHandler = (evt) => {
    setEnteredStadiumZone(evt.target.value);
  };

  const changeMemoHandler = (evt) => {
    const value = evt.target.value;
    setEnteredMemo(value);
  };

  const changeIsMangerHandler = (evt) => {
    setEnteredIsManger(evt.target.checked);
  };

  const changeDateHandler = (evt) => {
    if (evt.target.value === '') {
      return;
    }
    setEnteredDate(new Date(evt.target.value));
  };

  const changeStartTimeHandler = (evt) => {
    setEnteredStartTime({
      optionVal: evt.target.value,
      optionTxt: evt.target.value.split('T')[1],
    });
  };

  const submitHandler = (evt) => {
    evt.preventDefault();

    const createSocialRequest = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        onSetSocialAddRequestList((prevState) => {
          return [...prevState, data];
        });
        return;
      }

      alert(data.message);
    };

    sendSocialRequest(
      {
        urlPath: 'social-requests/individual/',
        method: 'POST',
        body: {
          request_type: 'ADD',
          zone_id: enteredStadiumZone,
          comment: enteredMemo,
          is_manager: enteredIsManger,
          start_date_time: enteredStartTime.optionVal,
        },
      },
      createSocialRequest
    );
  };

  return (
    <Modal title={title} onClose={onClose} classList={['modalSmall']}>
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='stadium-zone' className={classes.InputLabel}>
              구역
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'stadium-zone',
                value: enteredStadiumZone,
                onChange: changeStadiumZoneHandler,
              }}
              options={currentStadium.zones}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='appointment-date' className={classes.InputLabel}>
              일정
            </label>
            <InputDate
              attribute={{
                id: 'appointment-date',
                value: enteredDate,
                onChange: changeDateHandler,
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label
              htmlFor='appointment-time'
              className={`${classes.InputLabel} ${classes.hidden}`}
            >
              시간
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'appointment-time',
                value: enteredStartTime.optionVal,
                onChange: changeStartTimeHandler,
              }}
              options={timeOptions('start_time', enteredDate)}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='is-manager' className={classes.InputLabel}>
              매니저
            </label>
            <Checkbox
              attribute={{
                name: 'is-manager',
                onChange: changeIsMangerHandler,
                defaultChecked: enteredIsManger,
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap classList={['align--flex-start']}>
            <label
              htmlFor='memo'
              className={`${classes.InputLabel} ${classes.textarea}`}
            >
              코멘트
            </label>
            <Textarea
              attribute={{
                id: 'memo',
                value: enteredMemo,
                onChange: changeMemoHandler,
                placeholder: '메모',
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <Button attribute={{ type: 'submit' }}>확인</Button>
        </div>
      </form>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default SocialAddRequestForm;
