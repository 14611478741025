import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CommonLayout from '../../components/layouts/CommonLayout';
import ZoneList from '../../components/Scheduler/ZoneList/ZoneList';
import SocialAddRequestList from '../../components/SocialAddRequestList/SocialAddRequestList';
import SocialMatches from '../../components/SocialMatchList/SocialMatchList';
import SocialRequestList from '../../components/SocialRequestList/SocialRequestList';
import Loader from '../../components/UI/Loader/Loader';
import AuthContext from '../../contexts/auth-context';
import useHttp from '../../hooks/use-http';
import { adjDateFormat } from '../../utils/datetimeformat';

const MatchRequest = () => {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const [socialMatchList, setSocialMatchList] = useState([]);
  const [socialAddRequestList, setSocialAddRequestList] = useState([]);
  const [socialRequestList, setSocialRequestList] = useState([]);
  const { isLoading, sendRequest } = useHttp();

  const zoneList = authCtx.stadiumsList.find((stadium) => {
    return stadium.id === +params.stadiumId;
  }).zones;

  useEffect(() => {
    const today = new Date();
    let startDate = adjDateFormat(today);
    let endDate = adjDateFormat(
      new Date(today.getFullYear(), today.getMonth() + 2, 1)
    );

    // 구장의 소셜매치 일정
    const storeSocialMatchList = async (res) => {
      const data = await res.json();

      if (res.ok) {
        return setSocialMatchList(data.products);
      }

      alert(data.message);
    };

    sendRequest(
      {
        urlPath: `products?startDate=${startDate}&endDate=${endDate}&zoneId=${params.zoneId}&productType=소셜매치`,
      },
      storeSocialMatchList
    );

    // 구장의 소셜매치 추가 요청
    const storeSocialAddRequestList = async (res) => {
      const data = await res.json();

      if (res.ok) {
        return setSocialAddRequestList(data.socialRequests);
      }

      alert(data.message);
    };

    sendRequest(
      {
        urlPath: `social-requests/individual/?zoneId=${params.zoneId}&scheduleType=INDIVIDUAL&requestType=ADD`,
      },
      storeSocialAddRequestList,
      false
    );

    // 구장의 소셜매치 변경/취소 요청
    const storeSocialRequestList = async (res) => {
      const data = await res.json();

      if (res.ok) {
        return setSocialRequestList(data.socialRequests);
      }

      alert(data.message);
    };
    sendRequest(
      {
        urlPath: `social-requests/individual/?zoneId=${params.zoneId}&scheduleType=INDIVIDUAL`,
      },
      storeSocialRequestList,
      false
    );
  }, [params.zoneId, sendRequest]);

  return (
    <>
      <CommonLayout>
        <ZoneList />
        <SocialRequestList
          socialRequestList={socialRequestList}
          setSocialRequestList={setSocialRequestList}
          setSocialMatchList={setSocialMatchList}
          zoneList={zoneList}
        />
        <hr />
        <SocialAddRequestList
          socialAddRequestList={socialAddRequestList}
          setSocialAddRequestList={setSocialAddRequestList}
          zoneList={zoneList}
        />
        <hr />
        <SocialMatches
          socialMatchList={socialMatchList}
          setSocialMatchList={setSocialMatchList}
          setSocialRequestList={setSocialRequestList}
        />
      </CommonLayout>
      {isLoading && <Loader />}
    </>
  );
};

export default MatchRequest;
